<template>
  <client-only>
  <el-dialog append-to-body align-center @close="getClose"  :show-close="false" :close-on-click-modal="false" destroy-on-close class="mytest" style="width: 800px" footer-hide="false" v-model="modal_login" title="">
    <div class="boxLogin">
      <div class="boxLeft">
        <div class="boxLeft_box">
          <div class="title">正版商用素材服务商</div>
          <div class="website"><span>www.website.com</span></div>
          <div class="list">
            <div class="list_line">
              <div class="image">
                <i class="iconfont icon-duihao1" style="color: #FFFFFF;font-size: 20px"></i>
              </div>
              <div class="text">海量素材，高清图片资源</div>
            </div>
            <div class="list_line">
              <div class="image">
                <i class="iconfont icon-duihao1" style="color: #FFFFFF;font-size: 20px"></i>
              </div>
              <div class="text">正版授权，全站素材可商用</div>
            </div>
            <div class="list_line">
              <div class="image">
                <i class="iconfont icon-duihao1" style="color: #FFFFFF;font-size: 20px"></i>
              </div>
              <div class="text">资源持续更新,天天有新图</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="boxRightTitle" v-show="conversion">{{
              title === '手机验证码登录' ? '账号密码登录' : "手机验证码登录"
            }}
          </div>
          <div class="switchSty">
            <i @click="getClose()" style="color: #FFFFFF;font-size: 20px;cursor: pointer" class="iconfont icon-guanbi"></i>
          </div>
        </div>
        <div class="demo-login">
          <!-- 微信登录 -->
          <div class="loginWx" v-show="!conversion">
            <div class="loginWxTitle">
              微信扫码安全登录
            </div>
            <div class="loginWxImg"><img :src="imgData" alt=""></div>
            <div class="loginWxText">使用微信扫码关注「xxx」即可登录，</div>
            <div class="loginWxText">无需注册 扫码即同意猴图网</div>
            <div class="loginWxText"> <el-link :underline="false">《用户协议》</el-link>、<el-link :underline="false">《隐私政策》</el-link></div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
    </client-only>
</template>
<script setup lang="ts" ssr="false">
import {ref, reactive} from "vue";
import {getWechatImage, getWechatLogin} from '/src/api/apiconfig/main';
import {getLocal, setLocal,setLocalLogin} from "@/src/utils/local";
import { useLoginStore } from '@/src/store'
import { useRoute } from 'vue-router';
const loginStore = useLoginStore()
let modal_login = ref(false);
let telephone = ref(false)
let title = ref('手机验证码登录')
let conversion = ref(false)
let iconShow = ref(false)
const imgData = ref('/img/qr-loading.gif')
let uniqid = ref()
let data = ref([])
const timing = ref()
const timingNewImg = ref()
const uid = ref()
const avatarData = ref()
const emit = defineEmits(['login'])
const props = defineProps({
  data: {
    type: Number,
    required: true
  }
})
//登录方式切换
const getSwitch = () => {
  if (conversion.value === false) {
    conversion.value = true
    iconShow.value = true
  } else {
    conversion.value = false
    iconShow.value = false
    //获取微信二维码
    getWechatData()
  }
}
//手机登录账号登录方式切换
const getTelephoneSwitch = () => {
  if (telephone.value === false) {
    title.value = '账号密码登录'
    telephone.value = true
  } else {
    title.value = '手机验证码登录'
    telephone.value = false
  }
}
const getRetrievePass = () => {
  //console.log("找回密码");
}
//获取微信二维码
const getWechatData = (async () => {
  //清空定时器
  if (timing.value) {
    clearInterval(timing.value)
  }
  if (timingNewImg.value) {
    clearInterval(timingNewImg.value)
  }
  const response = await getWechatImage({})
  if (response.data) {
    if(modal_login.value == true){
      imgData.value = response.data.img
      uniqid.value = response.data.uniqid
    }
    // //每隔2秒获取一次状态
    timing.value = setInterval(() => {
      getLoginState()
    }, 2000)
    //每隔30秒获取一次新的二维码
    timingNewImg.value = setInterval(() => {
      getWechatData()
    }, 30000)
    //定时器10分钟不在获取登录状态
    setTimeout(() => {
      clearInterval(timing.value)
      clearInterval(timingNewImg.value)
      loginStore.closeloginstate()
    }, 600000)
  }
})
//获取登录状态
const getLoginState = (async () => {
  const response = await getWechatLogin({
    uniqid: uniqid.value
  });
  if (response) {
    if (response.data.token) {
      //setLocal("uid", response.data.uid)
      setLocalLogin('uid',response.data.uid);
      setLocalLogin('userid',response.data.userid);
      setLocalLogin('group_id',response.data.group_id);
      //setLocal("username", response.data.username)
      //setLocal("avatar", response.data.avatar)
      setLocalLogin("token", response.data.token)
      //uid.value = response.data.uid;
      //emit('login', true)
      getClearTiming()
      loginStore.closeloginstate()
    }
  } else {
    getClearTiming()
  }
})
//清空定时器
const getClearTiming = (()=>{
  clearInterval(timing.value)
  clearInterval(timingNewImg.value)
})
//关闭
const getClose = () => {
  getClearTiming()
  loginStore.closeloginstate()
  modal_login.value = false;
}

watch(() => loginStore.loginshowstate, (newValue, oldValue) => {
  if(newValue == true){
    modal_login.value = true;
    getWechatData()
  }else{
    getClearTiming()
    modal_login.value = false;
  }
}, {
  immediate: true // 组件挂载后立即执行一次
})
</script>
<style lang="scss" scoped>
.boxLogin {
  width: 800px;
  height: 450px;
  //margin-left: -16px;
  display: flex;
  //margin-top: -35px;
  background-color: #FFFFFF;
  border-radius: 10px;

  .boxLeft {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 320px;
    height: 450px;
    background-color: #DF7640;
    padding: 0 50px;

    .boxLeft_box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-weight: 600;
        font-size: 25px;
        color: #FFFFFF;
        margin-top: 50px;
      }

      .website {
        width: 200px;
        height: 30px;
        background-color: #C55C24;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px;

        span {
          color: #FFFFFF;
          font-size: 17px;
        }
      }

      .list {
        width: 100%;
        height: 200px;
        .list_line{
          display: flex;
          margin-top: 40px;
          .image {
          }

          .text {
            margin-left: 10px;
            color: #FFFFFF;
            font-size: 17px;
            font-weight: 520;
            letter-spacing: 3px;
          }
        }
      }
    }
  }

  .boxRightTitle {
    margin: 100px 40px 0px;
    font-size: 20px;
    width: 50%;
    font-weight: 700;
    text-align: left;
    color: #000000CC;
  }

  .switchSty {
    width: 35px;
    height: 35px;
    background-color: rgba(119, 119, 119, 0.9);
    position: absolute;
    top: 45px;
    left: 750px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    float: right;
    font-size: 50px;
    color: #767B87;
    margin-top: -15px;
  }

  .icon:hover {
    color: #1A2435;
  }

  .triangle {
    width: 70px;
    height: 64px;
    top: 0;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0;
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    z-index: 2;
  }

  .login {
    margin-left: 40px;
    margin-top: 30px
  }

  .userName {
    width: 356px;
  }

  .forgotPassword {
    color: #0773fc;
    cursor: pointer;
  }

  .retrievePass {
    cursor: pointer;
    float: right;
  }

  .submit {
    margin-top: 5px
  }

  .loginWx {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
    height: 450px;
  }

  .loginWxTitle {
    width: 264px;
    height: 24px;
    margin-top: 55px;
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, .8);
    text-align: center;
  }

  .loginWxImg {
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    margin-top: 25px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      width: 200px;
      height: 200px;
    }
  }

  .loginWxText {
    width: 264px;
    height: 12px;
    color: #8693AB;
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
  }

  .close {
    width: 40px;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 100%;
    margin-left: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

  }
}

.personalInfo {
  width: 200px;
  height: 100px;
  background-color: #f5e9e9;
  position: absolute;
  right: 10px;
  top: 62px
}
</style>
<style>

.mytest {
  --el-dialog-bg-color: unset !important;
  --el-dialog-box-shadow: unset !important;
}

</style>